@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "bdelectrobazarregular";
  src: url("./bd-electrobazar-regular-webfont.woff2") format("woff2"),
    url("./bd-electrobazar-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {

}
body {
  font-family: "bdelectrobazarregular" !important;
}

.bd {
  font-family: "bdelectrobazarregular" !important;
}

p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
}
blockquote {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 23px;
}
pre {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 23px;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.height {
  height: 80vh;
}
:root {
  --swiper-theme-color: #000 !important;
  background: rgb(24, 22, 22);
  background-size: 100vw 100vh;
}
div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}



.swiper {
  width: 60%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 70%;
  width: 100%;
}

.mySwiper2 > img {
  object-fit:contain;
}

.mySwiper {
  height: 15%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 10%;
  object-fit: contain;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
